import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_LINK,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default instance;
