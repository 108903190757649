import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import Cookies from '@/service/cookie';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: (to) => {

				if (!Cookies.get('token') && !Cookies.get('refreshTokenActive')) {
					return { name: 'login' }
				}

				const urlParams = new URLSearchParams(window.location.search);
				if (urlParams.get('redirect') == 'calendar' && store.getters.permissions.includes('section-access-calendar')) {
					return { name: 'calendar' };
				}

				// Handle different landing pages based on user roles.
				if (store.getters.permissions.includes('ticket-page-active')) {
					return { name: 'tickets' };
				} else if (store.getters.permissions.includes('tickets-page-mytickets')) {
					return { name: 'tickets.my-tickets' };
				} else if (store.getters.permissions.includes('tickets-page-unconfirmed')) {
					return { name: 'tickets.unverified' };
				} else if (store.getters.permissions.includes('tickets-page-language')) {
					return { name: 'tickets.language' };
				} else if (store.getters.permissions.includes('tickets-page-closed')) {
					return { name: 'tickets.completed' };
				} else if (store.getters.permissions.includes('tickets-page-unassigned')) {
					return { name: 'tickets.unassigned' };
				} else if (store.getters.permissions.includes('tickets-page-quality')) {
					return { name: 'tickets.quality' };
				} else if (store.getters.permissions.includes('tickets-page-statistics-new')) {
					return { name: 'tickets.statistics.new' };
				} else if (store.getters.permissions.includes('tickets-page-statistics')) {
					return { name: 'tickets.statistics' };
				} else if (store.getters.permissions.includes('tickets-page-users')) {
					return { name: 'tickets.users' };
				} else if (store.getters.permissions.includes('court-page-active')) {
					return { name: 'cases' };
				} else {
					return { name: 'no-access' };
				}
			},
		},

		/**
		 * SYSTEM
		 */
		{
			path: '/logout',
			name: 'logout',
			meta: { requiresPermission: false },
			component: () => import(/* webpackChunkName: "logout" */ './views/Logout.vue'),
		},
		{
			path: '/no-access',
			name: 'no-access',
			meta: { requiresPermission: false },
			component: () => import(/* webpackChunkName: "logout" */ './views/403.vue'),
		},

		{
			path: '/login',
			name: 'login',
			meta: { requiresPermission: false },
			component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
		},

		{
			path: '/login',
			name: 'login-refresh-token-expired',
			meta: { requiresPermission: false },
			props: (route) => ({ tokenExpired: true }),
			component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
		},

		{
			path: '/reset-password/:token',
			name: 'login.reset-password',
			meta: { requiresPermission: false },
			props: (route) => ({ token: String(route.params.token) }),
			component: () => import(/* webpackChunkName: "login" */ './views/User/ResetPassword.vue'),
		},
		{
			path: '/otp-edit',
			name: 'otp-edit',
			meta: { requiresPermission: false },
			component: () => import(/* webpackChunkName: "login" */ './views/OtpEdit.vue'),
		},
		{
			path: '/otp-verify',
			name: 'otp-verify',
			meta: { requiresPermission: false },
			component: () => import(/* webpackChunkName: "login" */ './views/OtpVerify.vue'),
		},

		/**
		 * E-MAIL
		 */
		{
			path: '/email',
			name: 'email',
			meta: { requiresPermission: 'section-access-mail' },
			component: () => import(/* webpackChunkName: "email" */ './views/Email/Overview.vue'),
		},
		{
			path: '/email/lists',
			name: 'email.lists',
			meta: { requiresPermission: 'section-access-mail' },
			component: () => import(/* webpackChunkName: "email-lists" */ './views/Email/Lists.vue'),
		},
		{
			path: '/email/signatures',
			name: 'email.signatures',
			meta: { requiresPermission: 'section-access-mail' },
			component: () => import(/* webpackChunkName: "email-signatures" */ './views/Email/Signatures.vue'),
		},

		{
			path: '/calendar',
			name: 'calendar',
			meta: { requiresPermission: 'section-access-calendar' },
			props: route => ({ id: route.query.id }),
			component: () => import(/* webpackChunkName: "calendar" */ './views/Calendar/Overview.vue'),
		},
		{
			path: '/invite/calendar',
			name: 'invite.calendar',
			meta: {},
			props: route => ({ id: route.query.id }),
			component: () => import(/* webpackChunkName: "calendar" */ './views/Invite/Calendar/Overview.vue'),
		},


		/**
		 * WITNESS
		 */
		{
			path: '/cases',
			name: 'cases',
			meta: { requiresPermission: 'court-page-active' },
			component: () => import(/* webpackChunkName: "logout" */ './views/Witnesses/WitnessListView.vue'),
		},

		{
			path: '/cases/case/:id',
			name: 'case.parent',
			meta: { requiresPermission: 'court-page-active' },
			props: (route) => ({ id: Number(route.params.id) }),
			component: () => import(/* webpackChunkName: "ticket" */ './views/Witnesses/Witness.vue'),
			children: [
				{
					path: 'overview',
					name: 'case',
					meta: { requiresPermission: 'court-page-active' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "ticket-overview" */ './views/Witnesses/SingleWitness/Overview.vue'),
				}
			],
		},
		{
			path: '/cases/new-case',
			name: 'cases.new',
			meta: { requiresPermission: 'cases-new-case' },
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Witnesses/NewWitness.vue'),
		},
		{
			path: '/cases/times',
			name: 'cases.times',
			meta: { requiresPermission: 'court-page-time' },
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Witnesses/WitnessTimeListView.vue'),
		},
		{
			path: '/cases/time/:id',
			name: 'cases.time.parent',
			meta: { requiresPermission: 'court-page-time' },
			props: (route) => ({ id: Number(route.params.id) }),
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Witnesses/WitnessTime.vue'),
			children: [
				{
					path: 'overview',
					name: 'case.time',
					meta: { requiresPermission: 'court-page-time' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "ticket-overview" */ './views/Witnesses/SingleWitnessTime/Overview.vue'),
				}
			],
		},
		{
			path: '/cases/interview-d',
			name: 'cases.interview-d',
			meta: { requiresPermission: 'testimony-interview-d-view' },
			component: () => import(/* webpackChunkName: "logout" */ './views/Witnesses/InterviewDListView.vue'),
		},
		{
			path: '/cases/new-time',
			name: 'cases.new-time',
			meta: { requiresPermission: 'cases-new-time' },
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Witnesses/NewWitnessTime.vue'),
		},
		{
			path: '/cases/users',
			name: 'cases.users',
			meta: { requiresPermission: 'court-page-user' },
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Witnesses/WitnessUsersListView.vue'),
		},
		{
			path: '/cases/statistics',
			name: 'cases.statistics',
			meta: { requiresPermission: 'court-page-statistics' },
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Witnesses/WitnessStatisticsListView.vue'),
		},

		/**
		 * TICKETS
		 */
		{
			path: '/tickets',
			name: 'tickets',
			meta: { requiresPermission: 'ticket-page-active' },
			component: () => import(/* webpackChunkName: "logout" */ './views/Tickets/Active.vue'),
		},

		{
			path: '/tickets/ticket/:id',
			name: 'ticket.parent',
			meta: { requiresPermission: 'ticket-view-ticket' },
			props: (route) => ({ id: Number(route.params.id) }),
			component: () => import(/* webpackChunkName: "ticket" */ './views/Tickets/Ticket.vue'),
			children: [
				{
					path: 'overview',
					name: 'ticket',
					meta: { requiresPermission: 'ticket-view-ticket' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "ticket-overview" */ './views/Tickets/SingleTicket/Overview.vue'),
				},
				{
					path: 'history',
					name: 'ticket.history',
					meta: { requiresPermission: 'ticket-view-ticket' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "ticket-history" */ './views/Tickets/SingleTicket/History.vue'),
				},
				{
					path: 'related',
					name: 'ticket.related',
					meta: { requiresPermission: 'ticket-related-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "ticket-related" */ './views/Tickets/SingleTicket/Related.vue'),
				},
			],
		},

		{
			path: '/tickets/my-tickets',
			name: 'tickets.my-tickets',
			meta: { requiresPermission: 'tickets-page-mytickets' },
			component: () => import(/* webpackChunkName: "tickets-mytickets" */ './views/Tickets/MyTickets.vue'),
		},
		{
			path: '/tickets/all',
			name: 'tickets.all',
			meta: { requiresPermission: 'tickets-page-all' },
			component: () => import(/* webpackChunkName: "tickets-alltickets" */ './views/Tickets/AllTickets.vue'),
		},
		{
			path: '/tickets/users',
			name: 'tickets.users',
			meta: { requiresPermission: 'tickets-page-users' },
			component: () => import(/* webpackChunkName: "tickets-users" */ './views/Tickets/UserTickets.vue'),
		},
		{
			path: '/tickets/unverified',
			name: 'tickets.unverified',
			meta: { requiresPermission: 'tickets-page-unconfirmed' },
			component: () => import(/* webpackChunkName: "tickets-unverified" */ './views/Tickets/UnverifiedTickets.vue'),
		},
		{
			path: '/tickets/language',
			name: 'tickets.language',
			meta: { requiresPermission: 'tickets-page-language' },
			component: () => import(/* webpackChunkName: "tickets-language" */ './views/Tickets/LanguageTickets.vue'),
		},
		{
			path: '/tickets/completed',
			name: 'tickets.completed',
			meta: { requiresPermission: 'tickets-page-closed' },
			component: () => import(/* webpackChunkName: "tickets-completed" */ './views/Tickets/CompletedTickets.vue'),
		},
		{
			path: '/tickets/unassigned',
			name: 'tickets.unassigned',
			meta: { requiresPermission: 'tickets-page-unassigned' },
			component: () => import(/* webpackChunkName: "tickets-unassigned" */ './views/Tickets/UnassignedTickets.vue'),
		},
		{
			path: '/tickets/quality',
			name: 'tickets.quality',
			meta: { requiresPermission: 'tickets-page-quality' },
			component: () => import(/* webpackChunkName: "tickets-quality" */ './views/Tickets/Quality.vue'),
		},
		{
			path: '/tickets/new-ticket',
			name: 'tickets.new',
			meta: { requiresPermission: 'tickets-new-ticket' },
			component: () => import(/* webpackChunkName: "tickets-new-ticket" */ './views/Tickets/NewTicket.vue'),
		},
		{
			path: '/tickets/statistics',
			name: 'tickets.statistics',
			meta: { requiresPermission: 'tickets-page-statistics' },
			component: () => import(/* webpackChunkName: "tickets-statistics" */ './views/Tickets/StatisticsOld.vue'),
		},
		{
			path: '/tickets/statistics-new',
			name: 'tickets.statistics.new',
			meta: { requiresPermission: 'tickets-page-statistics-new' },
			component: () => import(/* webpackChunkName: "tickets-statistics" */ './views/Tickets/Statistics.vue'),
		},
		{
			path: '/tickets/statistics-events',
			name: 'tickets.statistics-events',
			meta: { requiresPermission: 'tickets-page-statistics-new' },
			component: () => import(/* webpackChunkName: "tickets-statistics" */ './views/Tickets/StatisticsEvents.vue'),
		},
		/**
		 * MEMBERS
		 */
		{
			path: '/members',
			name: 'members.members',
			meta: { requiresPermission: 'members-page-overview' },
			component: () => import(/* webpackChunkName: "members" */ './views/Members/Members.vue'),
		},
		{
			path: '/members/board',
			name: 'members.board',
			meta: { requiresPermission: 'members-page-board' },
			component: () => import(/* webpackChunkName: "members-board" */ './views/Members/MembersBoard.vue'),
		},
		{
			path: '/members/coordinators',
			name: 'members.coordinators',
			meta: { requiresPermission: 'members-page-coordinators' },
			component: () => import(/* webpackChunkName: "members-coordinators" */ './views/Members/MembersCoordinators.vue'),
		},
		{
			path: '/members/support',
			name: 'members.support',
			meta: { requiresPermission: 'members-page-volunteers' },
			component: () => import(/* webpackChunkName: "members-support" */ './views/Members/MembersSupport.vue'),
		},
		{
			path: '/members/downloads',
			name: 'members.downloads',
			meta: { requiresPermission: 'members-files-view' },
			component: () => import(/* webpackChunkName: "members-download" */ './views/Member/Downloads.vue'),
		},
		{
			path: '/offices',
			name: 'offices.offices',
			meta: { requiresPermission: 'offices-page-overview' },
			component: () => import(/* webpackChunkName: "offices" */ './views/Offices/Offices.vue'),
		},
		{
			path: '/offices/statistics',
			name: 'offices.statistics',
			meta: { requiresPermission: 'offices-page-statistics' },
			component: () => import(/* webpackChunkName: "offices" */ './views/Offices/Statistics.vue'),
		},

		{
			path: '/offices/localoffice/:id',
			name: 'offices.parent',
			meta: { requiresPermission: 'office-view-office' },
			component: () => import(/* webpackChunkName: "office" */ './views/Offices/LocalOffice.vue'),
			props: (route) => ({ id: Number(route.params.id) }),
			children: [
				{
					path: 'overview',
					name: 'localoffice.overview',
					meta: { requiresPermission: 'section-access-offices' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-overview" */ './views/LocalOffice/Overview.vue'),
				},
				{
					path: 'edit',
					name: 'localoffice.edit',
					meta: { requiresPermission: 'section-access-offices' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-edit" */ './views/LocalOffice/Edit.vue'),
				},
				{
					path: 'geography',
					name: 'localoffice.geography',
					meta: { requiresPermission: 'office-section-geography-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-geography" */ './views/LocalOffice/Geography.vue'),
				},
				{
					path: 'openinghours',
					name: 'localoffice.openinghours',
					meta: { requiresPermission: 'office-section-openinghours-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-opening" */ './views/LocalOffice/OpeningHours.vue'),
				},
				{
					path: 'quality',
					name: 'localoffice.quality',
					meta: { requiresPermission: 'office-section-quality-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-quality" */ './views/LocalOffice/Quality.vue'),
				},
				{
					path: 'information',
					name: 'localoffice.information',
					meta: { requiresPermission: 'office-section-currentinformation-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-information" */ './views/LocalOffice/CurrentInformation.vue'),
				},
				{
					path: 'statistics',
					name: 'localoffice.statistics',
					meta: { requiresPermission: 'office-section-statistics-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-information" */ './views/LocalOffice/Statistics.vue'),
				},
				{
					path: 'contact',
					name: 'localoffice.contact',
					meta: { requiresPermission: 'office-section-contact-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-contact" */ './views/LocalOffice/Contact.vue'),
				},
				{
					path: 'history',
					name: 'localoffice.history',
					meta: { requiresPermission: 'section-access-offices' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-history" */ './views/LocalOffice/History.vue'),
				},
				{
					path: 'support',
					name: 'localoffice.support',
					meta: { requiresPermission: 'office-section-volunteers-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-support" */ './views/LocalOffice/Support.vue'),
				},
				{
					path: 'board',
					name: 'localoffice.board',
					meta: { requiresPermission: 'office-section-boardmembers-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-board" */ './views/LocalOffice/Board.vue'),
				},
				{
					path: 'coordinator',
					name: 'localoffice.coordinator',
					meta: { requiresPermission: 'office-section-coordinators-view' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "office-coordinator" */ './views/LocalOffice/Coordinator.vue'),
				},
			],
		},

		{
			path: '/members/member/:id',
			name: 'member.parent',

			props: (route) => ({ id: Number(route.params.id) }),
			component: () => import(/* webpackChunkName: "member" */ './views/Members/Member.vue'),
			children: [
				{
					path: 'overview',
					name: 'member.overview',
					meta: { requiresPermission: 'member-view-member' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "member-overview" */ './views/Member/Overview.vue'),
				},

				{
					path: 'history',
					name: 'member.history',
					meta: { requiresPermission: 'member-view-member' },
					props: (route) => ({ id: Number(route.params.id) }),
					component: () => import(/* webpackChunkName: "member-history" */ './views/Member/History.vue'),
				},
			],
		},

		/* POLICE IMPORT */
		{
			path: '/tickets/police-import',
			name: 'police-import',
			meta: { requiresPermission: 'section-access-police-import' },
			component: () => import(/* webpackChunkName: "logout" */ './views/PoliceImport/Overview.vue'),
		},

		{
			path: '/tickets/police-import/imports',
			name: 'police-import.imports',
			meta: { requiresPermission: 'section-access-police-import' },
			component: () => import(/* webpackChunkName: "logout" */ './views/PoliceImport/Imports.vue'),
		},
		{
			path: '/tickets/police-import/ticket/:id',
			name: 'police-import-ticket',
			meta: { requiresPermission: 'section-access-police-import' },
			props: route => ({ id: route.query.id }),
			component: () => import(/* webpackChunkName: "police-import-ticket" */ './views/PoliceImport/PoliceImportTicket.vue'),
		},
		{
			path: "*",
			name: "404",
			component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.checkLoginData = async function() {
	if (!Cookies.get('token')) {
		return;
	}

	if (store.getters.user.user) { 
		return;
	}

		
	await Promise.all([
		store.dispatch('getUserInformation'),
		store.dispatch('getTicketStatuses'),
		store.dispatch('getMemberOptions'),
		store.dispatch('getLocalOfficeOptions'),
		store.dispatch('getTestimonyOptions'),
		store.dispatch('getPoliceCrimes'),
		store.dispatch('getPostal'),
		store.dispatch('getDistricts'),
		store.dispatch('getExposedAreas'),
		store.dispatch('getInfoBoxes'),
	]).catch((error) => {
	
		throw error;
	}); 

}
/**
 * afterEach hook
 *
 * Remove loading indicator after routing is completed.
 */
router.afterEach((to, from) => {
	router.app.$options.data().loading = false;
});

/**
 * beforeEach hook.
 *
 * Guard routes based on their permission property.
 */
router.beforeEach((to, from, next) => {
	router.app.$options.data().loading = true;

	if (to.name == 'logout') {
		next();
		return;
	}


	// Basic auth before permissions are loaded from API
	const publicPages = ['login', 'login.reset-password', 'invite.calendar'];
	const otpPages = ['otp-verify', 'otp-edit', 'logout'];
	const authRequired = !publicPages.includes(to.name);
	const loggedIn = Cookies.get('token');
	const otp = Cookies.get('otp');

	if (otpPages.includes(to.name)) {
		next();

		return false;
	}


	const RefreshTokenInUse = Cookies.get('refreshTokenActive');
	router.checkLoginData().then(function() {
		
		if (authRequired && !loggedIn && !RefreshTokenInUse || typeof store.getters.permissions === 'undefined') {
			next('/login');
		} else {
			if (!to.meta.requiresPermission) {
				next();
			} else {
				if (!store.getters.permissions.includes(to.meta.requiresPermission)) {
					next('/no-access');
				} else {
					next();
				}
			}
		}
	});
});


export default router;
