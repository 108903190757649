<template>
  <div class="loading-indicator" :class="{ 'is-loading': visible }">
    <i class="la la-circle-o-notch fa-spin"></i> {{ $t('labels.loading-indicator') }}
  </div>
</template>
<script>
export default {
  name: "loader",
  props: ["visible"]
};
</script>
<style lang="scss">
.loading-indicator {
  background-color: #32444e;
  color: #fff;
  position: fixed;
  bottom: -140px;
  transition: all .3s;
  right: 40px;
  padding: 15px 20px 15px 15px;
  z-index: 101111000;
  border-radius: 50px;
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 600;

  &.error-loading {
    background-color: #c0392b;
  }

  span {
    font-size: .8em;
    margin-left: 1em;
    position: relative;
    top: -3px;
  }

  i {
    color: #fff;
    font-size: 1.3em;
  }
}

.is-loading {
  bottom: 50px;
  animation-duration: 2s;
  animation-name: pulse-loading;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes pulse-loading {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(50, 68, 78, 0.3);
  }

  65% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(50, 68, 78, 0.6);
  }

  100% {
    transform: scale(.8);
    box-shadow: 0 0 0 0 rgba(50, 68, 78, 0.3);
  }
}
</style>
