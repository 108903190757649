import api from '@/service/api';
import Cookies from '@/service/cookie';

export default (store, router) => {

  api.interceptors.request.use(
    (config) => {

      const token = Cookies.get('token');
      if (token) {
        //console.log('sending auth bearrer token');
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      const otp = Cookies.get('otp');
      if (otp) {
        console.log('sending otp header');
        config.headers["Otp"] = otp;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  api.interceptors.response.use(
    function (response) {
      //console.log('interseption response')
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      console.log(error.response);
      if (
        error.response.status === 401 &&
        originalRequest.url.includes("/api/refresh-access-token")
      ) {
        console.log('response status 401 a');
        await store.dispatch("logout");

        Cookies.set('refreshTokenActive', true);

        router.push({
          name: 'login-refresh-token-expired'
        });

        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        console.log('response status 401 b');
        originalRequest._retry = true;

        await store.dispatch("refreshToken");

        return api(originalRequest);
      } else if (error.response.status === 403 && !originalRequest._retry && error.response.data && error.response.data == 'Requires otp auth') {
        Cookies.remove('otp');
        console.log('response status 403');
        //   router.push({ name: 'otp-verify' });
        router.push({
          name: 'otp-verify'
        }).catch(() => { });
        return Promise.reject(error);

        //   await store.dispatch("logout");

        // Cookies.set('refreshTokenActive', true);

        /*   router.push({
            name: 'login-refresh-token-expired'
          });
  
          return Promise.reject(error); */
      }
      console.log('response status unknown');
      return Promise.reject(error);
    }
  );
};
