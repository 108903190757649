import Cookies from 'js-cookie';

var domainName = process.env.VUE_APP_COOKIE_DOMAIN ? process.env.VUE_APP_COOKIE_DOMAIN : window.location.hostname;
const instance = Cookies.withAttributes({ path: '/', domain: '.' + domainName })

instance.clearCookies = function () {
    Object.keys(instance.get()).forEach(function (cookieName) {
        var neededAttributes = {
            // Here you pass the same attributes that were used when the cookie was created
            // and are required when removing the cookie
        };
        instance.remove(cookieName, neededAttributes);
    });
};

export default instance;
