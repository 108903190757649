import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import Axios from 'axios';
import Notifications from 'vue-notification';
import Sticky from 'vue-sticky-directive';
import InstantSearch from 'vue-instantsearch';
import vSelect from 'vue-select';
import { i18n } from '@/plugins/i18n';
import { Trans } from '@/plugins/Translation';
import VueTextareaAutosize from 'vue-textarea-autosize';
import ReactiveSearch from '@appbaseio/reactivesearch-vue';
import VModal from 'vue-js-modal';
import VueConfirmDialog from 'vue-confirm-dialog';
import * as VueGoogleMaps from 'vue2-google-maps';
import VTooltip from 'v-tooltip';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Permissions from '@waju/vue-guard/dist/vue-guard';
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import Vuelidate from 'vuelidate'
import interceptors from './service/interceptors';


Vue.use(Permissions, { router, errorRoute: '/403' });
Vue.use(VueLodash, { name: 'custom', lodash: lodash });

/**
 * Google Maps
 */
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyB_CQU3UxOwpF4uHKslmR95ibNyyzAJluw',
		libraries: 'places', // This is required if you use the Autocomplete plugin
	},
	installComponents: true,
});

/**
 * Sentry Logging
 */
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
// 	Vue,
// 	environment: process.env.NODE_ENV,
// 	dsn: process.env.VUE_APP_API_SENTRY,
// 	integrations: [
// 		new Integrations.BrowserTracing({
// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 			tracingOrigins: ["localhost", "minerva.test", /^\//],
// 		}),
// 	],
// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 1.0
// });

/* Install Plugins */
Vue.use(CKEditor);
Vue.use(VueFileAgent);
Vue.use(VueConfirmDialog);
Vue.use(ReactiveSearch);
Vue.use(VueTextareaAutosize);
Vue.use(InstantSearch);
Vue.use(Sticky);
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(VueLodash);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(Vuelidate)

/* Install Components */
Vue.component('v-select', vSelect);

/* Vue Config */
Vue.config.performance = false;
Vue.config.productionTip = false;

/* API Settings */
Vue.prototype.$http = Axios;
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.prototype.$to = (object, label) => {
	var field = label + '_' + i18n.locale;
	return object[field] ? object[field] : object[label];
}
Vue.prototype.$label = () => {
	return i18n.locale == 'en' ? 'label_en' : 'label';
}

Vue.prototype.$field = (field) => {
	return i18n.locale == 'en' ? field + '_en' : field;
}

/* Simple Directive used for permissions */
Vue.directive('can', {
	bind: function (el, binding, vnode) {
		if (!store.getters.permissions.includes(binding.value)) {
			el.style.display = 'none';
		}
	},
});

interceptors(store, router);

/* CSS */
import '@/assets/style/style.scss';
import 'vue-select/dist/vue-select.css';

new Vue({
	router,
	store,
	i18n,
	permissions() {
		return this.$store.getters.permissions;
	},
	data: { loading: false, errorLoading: false },
	render: (h) => h(App),
}).$mount('#app');
