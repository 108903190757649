import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import BojAPI from '@/api/boj';
import createPersistedState from 'vuex-persistedstate';
import * as Sentry from "@sentry/vue";
import Cookies from '@/service/cookie';

Vue.use(Vuex);

function initialState() {
	return {
		tickets: {},
		members: {},
		states: {},
		user: {},
		permissions: {},
		policeCrimes: {},
		ticketStatuses: [],
		memberOptions: {},
		testimonyOptions: {},
		otpOptions: {},
		localOfficeOptions: {},
		language: 'sv',
		postal: {},
		districts: {},
		exposedAreas: {},
		infoBoxes: {},
		invitedUser: {},
	}
}

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state: initialState,
	mutations: {
		auth_request(state) {
			state.status = 'loading';
		},
		auth_success(state, response) {
			const token = response.access_token;

			const s = initialState();
			Object.keys(s).forEach(key => {
				state[key] = s[key]
			})
			
			state.status = 'success';

			if (token == null) {
				Cookies.remove('token');
			} else {
				Cookies.set('token', token);
				this.dispatch("setOtpOptions", response);
			}
		},
		auth_error(state) {
			state.status = 'error';
		},

		tickets_set(state, tickets) {
			state.tickets = tickets;
		},
		states_set(state, states) {
			state.states = states;
		},
		members_set(state, members) {
			state.members = members;
		},
		logout(state) {
			const s = initialState()
			Object.keys(s).forEach(key => {
				state[key] = s[key]
			})
		},

		SET_TICKET_STATUSES(state, payload) {
			state.ticketStatuses = payload;
		},

		SET_MEMBER_OPTIONS(state, payload) {
			state.memberOptions = payload;
		},

		SET_LOCALOFFICE_OPTIONS(state, payload) {
			state.localOfficeOptions = payload;
		},

		SET_TESTIMONY_OPTIONS(state, payload) {
			state.testimonyOptions = payload;
		},

		SET_OTP_OPTIONS(state, payload) {
			state.otpOptions.otp_channel = payload.otp_channel;
			state.otpOptions.otp_email = payload.otp_email;
			state.otpOptions.otp_sms = payload.otp_sms;
		},

		SET_USER(state, data) {
			state.user = data;
			state.permissions = data.permissions;
		},

		SET_INVITED_USER(state, data) {
			state.invitedUser = data;
		},


		SET_NEW_LANGUAGE(state, data) {
			state.language = data;
		},

		SET_POLICE_CRIMES(state, data) {
			state.policeCrimes = data;
		},

		SET_POSTAL(state, data) {
			state.postal = data;
		},

		SET_DISTRICTS(state, data) {
			state.districts = data;
		},

		SET_EXPOSED_AREAS(state, data) {
			state.exposedAreas = data;
		},

		SET_INFO_BOXES(state, data) {
			state.infoBoxes = data;
		},
	},
	actions: {
		/**
		 * Set a new language for the interface
		 *
		 * @param commit
		 * @param language
		 */
		setNewLanguage({ commit }, language) {
			commit('SET_NEW_LANGUAGE', language.lang);
		},

		auth_request(state) {
			state.status = 'loading';
		},

		auth_error(state) {
			state.status = 'error';
		},

		/**
		 *
		 * @param commit
		 * @returns {Promise<any> | Promise<postcss.Result>}
		 */
		getUserInformation({ commit }) {
			return BojAPI.getUserInformation()
				.then((response) => {
					commit('SET_USER', response.data);
					Sentry.setUser({ email: response.data.user.email });
				})
				.catch((error) => {
					//this.dispatch('logout');
					return;
					/* 	.then(() =>
							this.$router.push('/login')
						)
						.catch() */
					//throw error;
				});
		},

		getInvitedUser({ commit }) {
			return BojAPI.getInvitedUser()
				.then((response) => {
					commit('SET_INVITED_USER', response.data);
				})
				.catch((error) => {
					return;
				});
		},

		/**
		 *
		 * @param commit
		 * @param user
		 * @returns {Promise<any> | Promise<postcss.Result>}
		 */
		login({ commit }, user) {
			return BojAPI.login(user.username, user.password)
				.then((response) => {
					commit('auth_success', response.data);
					return response.data;
				})
				.catch((error) => {
					commit('auth_error');
					Cookies.remove('token');
					throw error;
				});
		},

		refreshToken({ commit }) {
			return BojAPI.refreshAccessToken().then(response => {
				commit('auth_success', response.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {*}
		 */
		getTicketStatuses({ commit }) {
			return BojAPI.getStatuses().then((response) => {
				commit('SET_TICKET_STATUSES', response.data.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {*}
		 */
		getPoliceCrimes({ commit }) {
			return BojAPI.getPoliceCrimes().then((response) => {
				commit('SET_POLICE_CRIMES', response.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {*}
		 */
		getPostal({ commit }) {
			return BojAPI.getPostal().then((response) => {
				commit('SET_POSTAL', response.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {*}
		 */
		getDistricts({ commit }) {
			return BojAPI.getDistricts().then((response) => {
				commit('SET_DISTRICTS', response.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {*}
		 */
		getExposedAreas({ commit }) {
			return BojAPI.getExposedAreas().then((response) => {
				commit('SET_EXPOSED_AREAS', response.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {*}
		 */
		getInfoBoxes({ commit }) {
			return BojAPI.getInfoBoxes().then((response) => {
				commit('SET_INFO_BOXES', response.data);
			});
		},

		/**
		 * Retrieve options for dropdowns for member forms.
		 *
		 * @param commit
		 * @returns {*}
		 */
		getMemberOptions({ commit }) {
			return BojAPI.getMemberOptions().then((response) => {
				commit('SET_MEMBER_OPTIONS', response.data);
			});
		},

		/**
		 * Retrieve options for dropdowns for local office forms.
		 *
		 * @param commit
		 * @returns {*}
		 */
		getLocalOfficeOptions({ commit }) {
			return BojAPI.getLocalOfficeOptions().then((response) => {
				commit('SET_LOCALOFFICE_OPTIONS', response.data);
			});
		},

		/**
		 * Retrieve options for testimony.
		 *
		 * @param commit
		 * @returns {*}
		 */
		getTestimonyOptions({ commit }) {
			return BojAPI.getTestimonyOptions().then((response) => {
				commit('SET_TESTIMONY_OPTIONS', response.data);
			});
		},
		setOtpOptions({ commit }, data) {
			commit('SET_OTP_OPTIONS', data);
		},

		getOtpOptions({ commit }) {
			return BojAPI.getOtpOptions().then((response) => {
				commit('SET_OTP_OPTIONS', response.data);
			});
		},

		/**
		 *
		 * @param commit
		 * @returns {Promise<unknown>}
		 */
		logout({ commit }) {
			return BojAPI.logout()
				.catch(e => {
					console.log('serverside error - continue on frontend with finally promise')
				})
				.finally((repsonse) => {
					commit('logout');
					localStorage.clear();
					Cookies.remove('token');
					delete Axios.defaults.headers.common['Authorization'];
					Sentry.configureScope(scope => scope.setUser(null));

					// Also logout on Witness site
					let urlParams = new URLSearchParams(window.location.search);
					if (urlParams.get('sender') != 'witness') {
						var f = document.createElement('iframe');
						f.src = process.env.VUE_APP_WITNESS_LINK + '/logout?sender=minerva';
						f.style.display = 'none';
						document.body.appendChild(f); 
					}
					
				});
		},
	/* 	clearCookies() {
			Object.keys(Cookies.get()).forEach(function (cookieName) {
				var neededAttributes = {
					// Here you pass the same attributes that were used when the cookie was created
					// and are required when removing the cookie
				};
				Cookies.remove(cookieName, neededAttributes);
			});
		} */

		// /**
		//  *
		//  * @param commit
		//  * @returns {Promise<unknown>}
		//  */
		// clearAuth({ commit }) {
		// 	return BojAPI.logout().then((repsonse) => {
		// 		commit('logout');
		// 		console.log('logout clearAuth')
		// 		Cookies.clear();
		// 		commit('using_refresh_token');
		// 		delete Axios.defaults.headers.common['Authorization'];
		// 		Sentry.configureScope(scope => scope.setUser(null));
		// 	});
		// },
	},
	getters: {
		user: (state) => state.user,
		invitedUser: (state) => state.invitedUser,
		permissions: (state) => state.permissions,
		language: (state) => state.language,
		states: (state) => state.states,
		tickets: (state) => state.tickets.data,
		memberOptions: (state) => state.memberOptions,
		localOfficeOptions: (state) => state.localOfficeOptions,
		testimonyOptions: (state) => state.testimonyOptions,
		otpOptions: (state) => state.otpOptions,
		getPoliceCrimes: (state) => state.policeCrimes,
		getPoliceCrimeByCrimeId: (state) => (id) => {
			return state.policeCrimes.filter((policeCrime) => policeCrime.crime_id === id);
		},
		getPoliceCrimeById: (state) => (id) => {
			return state.policeCrimes.filter((policeCrime) => policeCrime.id === id);
		},
		getPoliceCrimeByCode: (state) => (id) => {
			return state.policeCrimes.filter((policeCrime) => policeCrime.police_crime_code === id);
		},
		getTicket: (state) => (id) => {
			return state.tickets.data.find((ticket) => ticket.id === id);
		},
		getInfoBox: (state) => (field) => {
			return state.infoBoxes.find((infoBox) => infoBox.field === field);
		},
		getMember: (state) => (id) => {
			return state.members.data.find((member) => member.id === id);
		},
		getTicketStatuses: (state) => {
			return state.ticketStatuses.filter((i) => i.show_on_summary === 1);
		},
		getPostalByMunicipality: (state) => (id) => {
			return state.postal.filter((i) => i.municipality_id === id);
		},
		getPostalByPostalCode: (state) => (code) => {
			return state.postal.filter((i) => i.postal_code == code);
		},
		getDistrictsByMunicipality: (state) => (id) => {
			return state.districts.filter((i) => i.municipality_id === id);
		},
		getExposedAreasByMunicipality: (state) => (id) => {
			return state.exposedAreas.filter((i) => i.municipality_id === id);
		},
	},
});
